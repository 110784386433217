/*--
    - Component - Table
--------------------------------------*/

/* Product, Order, Invoice & Contact List Table */
table.order-list-table,
table.invoice-list-table,
table.product-list-table,
table.cart-list-table,
table.contact-list-table {
    margin-bottom: 0;
    & thead {
        & tr {
            & th {
                border: none !important;
                &:last-child {
                    padding-right: 40px;

                    text-align: right;
                }
            }
        }
    }
    & tbody {
        & tr {
            & td {
                vertical-align: middle;
                white-space: nowrap;

                border-width: 0 0 1px;
                &:last-child {
                    padding-right: 40px;
                }

                & .product {
                    overflow: hidden;

                    @media #{$extra-small-mobile} {
                        max-width: 220px;
                    }
                }
                & .product-thumb {
                    width: 70px;
                    @media #{$extra-small-mobile} {
                        max-width: 50px;
                    }
                    & img {
                        width: 100%;
                    }
                    & + .product-info {
                        width: calc(100% - 70px);
                        padding-left: 20px;
                        @media #{$extra-small-mobile} {
                            width: calc(100% - 50px);
                            padding-left: 10px;

                            white-space: normal;
                        }
                    }
                }
                & .product-info,
                & .customer-info,
                & .billing-info,
                & .ship-info {
                    align-self: center;
                    & .title {
                        margin-bottom: 5px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                        & a {
                            color: $heading-color;
                        }
                        @media #{$large-mobile} {
                            font-size: 16px;
                        }
                        @media #{$extra-small-mobile} {
                            font-size: 14px;
                        }
                    }
                    & p {
                        margin-bottom: 6px;

                        color: $body-color;
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
                & .customer-rating {
                    display: flex;
                    & .rating {
                        font-size: 16px;
                        line-height: 1;

                        position: relative;

                        color: $border-color;
                        &::before {
                            font-family: $fontAwesome;

                            content: "";
                        }
                        & .rate {
                            line-height: inherit;

                            position: absolute;
                            top: 0;
                            left: 0;

                            overflow: hidden;

                            height: 100%;

                            color: #F6CF73;
                            &::before {
                                font-family: $fontAwesome;

                                content: "";
                            }
                        }
                    }
                }
                & .product-quantity {
                    position: relative;

                    display: inline-flex;
                    & .input-qty {
                        width: 66px;
                        padding: 10px;
                        padding-right: 30px;
                    }
                    & .qty-btn {
                        font-size: 18px;

                        position: absolute;
                        right: 0;

                        display: flex;
                        align-items: center;
                        justify-content: center;

                        width: 30px;
                        height: 50%;

                        cursor: pointer;
                        user-select: none;

                        border-left: 1px solid $border-color;
                        &.minus {
                            bottom: 0;
                        }
                        &.plus {
                            top: 0;
                        }
                    }
                }
                & .badge {
                    &.badge-lg {
                        font-size: 14px;

                        display: inline-flex;

                        padding: 9px 15px 10px;

                        border-radius: 5px;
                    }
                }
                & .product-action {
                    display: flex;
                    justify-content: flex-end;
                    & .btn {
                        height: auto;
                        padding: 5px;

                        color: #C7C7C7;
                        border: none;
                        background-color: transparent;
                        & + .btn {
                            margin-left: 20px;
                        }
                        & i {
                            font-size: 16px;
                        }
                        &:hover {
                            &.edit {
                                color: $primary;
                            }
                            &.delete {
                                color: $danger;
                            }
                        }
                    }
                }
            }
        }
    }
    &.table-borderless {
        & tbody {
            & tr {
                & td {
                    border: none;
                }
                &:first-child {
                    & td {
                        padding-top: 24px !important;
                    }
                }
                &:last-child {
                    & td {
                        padding-bottom: 24px !important;
                    }
                }
            }
        }
    }
}