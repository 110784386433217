/*--
    - TinyMCE
--------------------------------------*/

.tox {
    &.tox-tinymce {
        border-color: $border-color;

        & .tox-editor-container {
            & .tox-editor-header {
                & .tox-menubar {
                }
                & .tox-toolbar-overlord {
                    & .tox-toolbar__primary {
                        border-top-color: $border-color;
                        border-bottom: 1px solid $border-color;
                        background-image: none;
                        & .tox-toolbar__group {
                            &:not(:last-of-type) {
                                border-right-color: $border-color;
                            }
                        }
                    }
                }
            }
        }
        & .tox-statusbar {
            border-top-color: $border-color;
        }
    }

    & .tox-menu {
        border-color: $border-color !important;
        &.tox-collection--list {
            & .tox-collection__group {
                border-color: $border-color;
            }
        }
    }
}