/*--
    - Component - Example Chart
--------------------------------------*/

/* Example Chart JS */
.example-chartjs {
    min-height: 200px;
}

/* Example Morris Chart */
.example-morris {
    width: 100%;
    height: 250px;
}
.morris-chart {
    & svg {
        width: 100%;
    }
}