/*--
    - Component - History
--------------------------------------*/

/* Report History */
.report-history {
    margin-bottom: 0;
    padding-left: 0;

    list-style: none;
    &[data-simplebar="init"] {
        padding-right: 10px;
    }
    & .media {
        & + .media {
            margin-top: 15px;
            padding-top: 15px;

            border-top: 1px solid $border-color;
        }
        & .media-thumb {
            width: 50px;
            height: 50px;
            & img {
            }
        }
        & .media-body {
            max-width: calc(100% - 50px);
            padding-left: 20px;
            & .media-title {
                font-size: 14px;
                font-weight: 600;

                margin-bottom: 5px;
                & span {
                    text-transform: uppercase;

                    color: #FF8787;
                }
            }
        }
    }
}