/*--
    - Spinner
--------------------------------------*/
.spinner-border-lg {
    width: 48px;
    height: 48px;

    border-width: 5px;
}
.spinner-grow-lg {
    width: 48px;
    height: 48px;
}
