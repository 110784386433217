.fullcalendar {
    &.fc {
        & .fc-toolbar {
            flex-wrap: wrap;

            margin-bottom: 10px;
            @media #{$large-mobile} {
                flex-direction: column;
            }
            & .fc-toolbar-chunk {
                margin-bottom: 10px;
                & .fc-button {
                    font-size: 14px;
                    font-weight: 600;

                    display: inline-flex;
                    justify-content: center;

                    height: 36px;
                    padding: 6px 12px;

                    text-transform: capitalize;

                    opacity: 1;
                    color: $primary;
                    border-color: transparent;
                    background-color: rgba($primary, 0.15);
                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }
                    &:disabled {
                        cursor: not-allowed;
                    }
                    &.fc-today-button {
                        &:not(:disabled) {
                            &:hover {
                                color: $white;
                                border-color: $primary;
                                background-color: $primary;
                            }
                        }
                    }
                    &:not(.fc-button-active) {
                        &:hover {
                            color: $primary;
                            border-color: transparent;
                            background-color: rgba($primary, 0.3);
                        }
                    }
                    &-active {
                        color: $white;
                        border-color: $primary;
                        background-color: $primary;
                        &:hover {
                            color: $white;
                        }
                    }
                    &.fc-prev-button,
                    &.fc-next-button {
                        width: 36px;
                        padding: 6px;
                        &:hover {
                            color: $white;
                            border-color: $primary;
                            background-color: $primary;
                        }
                    }
                }
                & .fc-button-group {
                    overflow: hidden;

                    border-radius: 4px;
                    & .fc-button {
                        border-radius: 0;
                    }
                }
                & .fc-toolbar-title {
                }
            }
        }
        & .fc-view-harness {
            & .fc-view {
                & .fc-scrollgrid {
                    border-color: $border-color;
                    & tbody {
                        & tr {
                            &.fc-scrollgrid-section {
                                & .fc-timegrid-divider {
                                    border-color: $border-color;
                                    background-color: $grey;
                                }
                                &-header {
                                    background-color: $grey;
                                    & .fc-col-header {
                                        & tbody {
                                            & tr {
                                                & .fc-col-header-cell {
                                                    border-color: $border-color;
                                                    & a {
                                                        line-height: 20px;

                                                        padding: 10px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                &-body {
                                    & tbody {
                                        & tr {
                                            & td {
                                                border-color: $border-color;
                                                &.fc-day-today {
                                                    background-color: $border-color;
                                                }
                                                &.fc-day-past,
                                                &.fc-day-future {
                                                    &.fc-day-other {
                                                        background-image: linear-gradient(-45deg, #F1F5FA 25%, transparent 25%, transparent 50%, $white 50%, #EAF0F9 75%, transparent 75%, transparent);
                                                        background-size: 10px 10px;
                                                    }
                                                }
                                                & .fc-timegrid-col-events {
                                                    margin: 0;
                                                }
                                                & a {
                                                    &.fc-event {
                                                        font-size: 13px;

                                                        margin: 0;
                                                        padding: 10px;

                                                        color: $heading-color;
                                                        border: none;
                                                        border-radius: 0;
                                                        &.fc-timegrid-event {
                                                            padding: 5px 10px;
                                                            & .fc-event-main {
                                                                color: inherit !important;
                                                            }
                                                        }
                                                        & * {
                                                            color: inherit;
                                                        }
                                                        & .fc-event-main {
                                                            padding: 0;
                                                        }
                                                        & .fc-daygrid-event-dot {
                                                            display: none;
                                                        }
                                                        & .fc-event-time {
                                                            font-weight: 600;
                                                            line-height: 1;
                                                        }
                                                        & .fc-event-title {
                                                            font-weight: 400;

                                                            padding: 0;
                                                        }
                                                        @each $name, $color in $theme-colors {
                                                            &.#{$name} {
                                                                border-left: 4px solid $color;
                                                                background-color: rgba($color, 0.3);
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}