/*--
    - Component - Add Product
--------------------------------------*/

/* Add Product */
/* Add Product Image */
.add-product-image {
    position: relative;

    overflow: hidden;

    border: 4px solid $border-color;
    border-radius: 10px;
    & .product-status {
        position: absolute;
        top: 20px;
        right: 20px;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 26px;
        height: 26px;

        transition: $transition-base;

        color: $white;
        border: 1px solid $border-color;
        border-radius: 50%;
        background-color: $white;
        &.sm {
            top: 16px;
            right: 16px;

            width: 20px;
            height: 20px;
            & i {
                font-size: 12px;

                opacity: 0;
            }
        }
        & i {
            line-height: 1;
        }
    }
    & img {
        width: 100%;
    }
    &:hover,
    &.active {
        & .product-status {
            border-color: $success;
            background-color: $success;
            & i {
                opacity: 1;
            }
        }
    }
}