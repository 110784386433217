.error-404 {
    text-align: center;
    & .image {
        margin-bottom: 50px;
        & img {
            max-width: 100%;
        }
    }
    & .content {
        & .title {
            font-size: 30px;
            font-weight: 700;

            margin-bottom: 30px;
        }
        & .btn {
        }
    }
}