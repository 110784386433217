/*--
    - Simple Bar Scroll
--------------------------------------*/

.simplebar-track {
    &.simplebar-vertical {
        width: 8px;
        & .simplebar-scrollbar {
            &.simplebar-visible {
                &::before {
                    opacity: 0.25;
                }
            }
        }
    }
}