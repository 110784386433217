/*--
    - Component - Invoice
--------------------------------------*/

/* Invoice */
/* Invoice Information */
.invoice-info {
    margin-bottom: 40px;
    & .invoice-id {
        font-size: 24px;
    }
}
/* Invoice Bill Information */
.invoice-bill-info {
    & + .invoice-bill-info {
        margin-top: 30px;
    }

    & .title {
        font-size: 18px;

        margin-bottom: 10px;

        color: $primary;
    }
    & .name {
        font-size: 24px;

        margin-bottom: 10px;
    }
    & p {
        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }
}
/* Invoice Payment Information */
.invoice-payment-info {
    & .title {
        font-size: 24px;

        margin-bottom: 20px;
    }
    & ul {
        margin-bottom: 0;
        padding: 0;

        list-style: none;

        & li {
            display: flex;
            flex-wrap: wrap;
            & + li {
                margin-top: 15px;
            }
            & span {
                &.label {
                    font-weight: 600;

                    display: flex;

                    width: 100px;
                    &::after {
                        margin-left: auto;

                        content: ":";
                    }
                }
                &.text {
                    width: calc(100% - 100px);
                    padding-left: 20px;
                }
            }
        }
    }
}