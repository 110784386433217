/*--
    - Toasrt
--------------------------------------*/
#toastrOptions {
    width: 100%;
    margin: 0;
    padding: 30px;

    white-space: pre-wrap;

    border: 1px solid #EEEEEE;
}
#toast-container {
    & .toast {
        max-width: none;
        margin-bottom: 10px;

        transition: $transition-base;

        border-radius: 4px;
        background-position: 15px 16px;
        box-shadow: 0 5px 10px rgba($black, 0.1);
        &:last-child {
            margin-bottom: 0;
        }
        &:hover {
            box-shadow: 0 10px 20px rgba($black, 0.1);
        }
        & .toast-progress {
            margin: 0;

            background-color: rgba($black, 0.3);
        }
        & .toast-close-button {
            font-weight: 300;
            line-height: 13px;

            display: flex;
            justify-content: center;

            width: 18px;
            height: 18px;
            &:hover {
                transform: rotate(90deg);

                opacity: 1;
                color: $white;
            }
        }
        & .toast-message {
            & a {
                position: relative;

                margin-bottom: 10px;
                &::before {
                    position: absolute;
                    right: 0;
                    bottom: 3px;
                    left: auto;

                    width: 0;
                    height: 1px;

                    content: "";
                    transition: all 0.3s ease 0s;

                    background-color: $white;
                }
                &:hover {
                    color: $white;
                    &::before {
                        left: 0;

                        width: 100%;
                    }
                }
            }
        }
        @each $name, $color in $theme-colors {
            &-#{$name} {
                background-color: $color !important;
            }
        }
    }
}
