/*--
    - Component - Transaction
--------------------------------------*/

/* Transaction */
/* Transaction Tab */
.transaction-tab {
    border-bottom: none;
    border-radius: 5px;
    background-color: #F9F9F9;
    & .nav-item {
        & .nav-link {
            font-size: 18px;
            font-weight: 600;
            line-height: 1;

            padding: 13px 20px;

            color: #656880;
            border: none;
            border-radius: 5px;
            &:hover {
                color: $primary;
            }
            &.active {
                color: $white;
                background-color: $primary;
            }
        }
    }
}
/* Transaction List */
.transaction-list {
    margin-bottom: 0;
    padding-right: 10px;
    padding-left: 0;

    list-style: none;
    & li {
        display: flex;
        flex-wrap: wrap;

        padding: 20px 0;
        & + li {
            border-top: 1px solid $border-color;
        }
        & > * {
            padding-right: 10px;
            padding-left: 10px;
            &:first-child {
                padding-left: 0;
            }
            &:last-child {
                padding-right: 0;
            }
        }
        & .transaction-icon {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 20px;
            height: 20px;
            margin-top: 3px;

            border: 1px solid $border-color;
            border-radius: 50%;
        }
        & .transaction-title {
            font-size: 18px;
            font-weight: 600;
        }
        & .transaction-date {
            font-size: 12px;
            font-weight: 400;
            line-height: 1;

            display: block;
        }
        & .transaction-id {
            font-size: 14px;
            font-weight: 400;
        }
        & .transaction-amount {
            font-size: 14px;
            font-weight: 600;
        }
        &.buy {
            & .transaction-icon {
                color: $primary;
                border-color: $primary;
            }
        }
        &.sale {
            & .transaction-icon {
                color: #E13B3B;
                border-color: #E13B3B;
            }
        }
    }
}
