.authentication-wrapper {
    min-height: 100vh;
}

.authentication-bg-image {
    width: 100%;
    height: 100%;

    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}


.login-rtegister-form {
    width: 100%;
    max-width: 360px;
    margin: auto;
    & .head {
        margin-bottom: 60px;

        text-align: center;
        & .title {
            line-height: 1.75;

            margin-bottom: 0;
        }
    }
    & .bottom {
        margin-top: 60px;

        text-align: center;
        & .title {
            line-height: 1;

            margin-bottom: 25px;
        }
    }
}


.lock-screen-wrapper {
    width: 100%;
    max-width: 780px;

    background-color: $white;
    & .lock-screen-head {
        padding: 95px 60px;

        background-repeat: no-repeat;
        background-position: center right;
        background-size: cover;
        @media #{$large-mobile} {
            padding: 50px 30px;
        }
        @media #{$extra-small-mobile} {
            padding: 30px;
        }
        & .title {
            font-size: 24px;

            margin-bottom: 20px;
            @media #{$small-mobile} {
                margin-bottom: 10px;
            }
        }
        & p {
            font-size: 18px;
            @media #{$small-mobile} {
                font-size: 14px;
            }
        }
    }
    & .lock-screen-body {
        padding: 60px;
        @media #{$small-mobile} {
            padding: 40px 30px;
        }
        & .lock-screen-user {
            margin-bottom: 30px;

            text-align: center;
            & .thumb {
                overflow: hidden;

                width: 110px;
                margin-right: auto;
                margin-bottom: 15px;
                margin-left: auto;

                border-radius: 12px;
                & img {
                    width: 100%;
                }
            }
            & .name {
                font-size: 18px;
                font-weight: 700;
                line-height: 1;

                margin-bottom: 0;

                color: $heading-color;
            }
        }
        & .lock-screen-form {
            max-width: 420px;
            margin: auto;
        }
    }
}