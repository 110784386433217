.chat-contacts {
    width: 100%;
    min-width: 300px;

    border-radius: 4px;
    background-color: $white;
    & .chat-contact-search {
        padding: 15px 25px;

        border-bottom: 1px solid $border-color;
        @media #{$large-mobile} {
            padding: 5px 25px;
        }
        & form {
            & .form-control {
                font-size: 16px;

                padding-right: 0;
                padding-left: 0;

                border: none;
            }
        }
    }
    & .chat-contact-list {
        padding: 10px;
        & ul {
            max-height: 545px;
            margin: 0;
            padding: 15px;

            list-style: none;
            @media #{$large-mobile} {
                max-height: 360px;
            }
            & li {
                display: flex;
                flex-wrap: wrap;

                cursor: pointer;
                & + li {
                    margin-top: 20px;
                }
                & .thumb {
                    overflow: hidden;

                    width: 50px;
                    height: 50px;

                    border-radius: 50%;
                    & img {
                        width: 100%;
                    }
                    & .status {
                    }
                }
                & .content {
                    overflow: hidden;
                    align-self: center;

                    width: calc(100% - 50px);
                    padding-left: 15px;
                    & .name {
                        line-height: 1;
                    }
                    & .last-message {
                        overflow: hidden;

                        max-width: 250px;

                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
    }
}

.chat-wrapper {
    border-radius: 5px;
    background-color: $white;
    & .active-contact {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        padding: 15px 25px 0;

        border-bottom: 1px solid $border-color;
        & .user {
            display: flex;
            & .thumb {
                overflow: hidden;

                width: 50px;
                height: 50px;

                border-radius: 50%;
                & img {
                    width: 100%;
                }
            }
            & .info {
                overflow: hidden;
                align-self: center;

                width: calc(100% - 50px);
                padding-left: 15px;
                & .name {
                    line-height: 1;
                }
                & .active-time {
                    overflow: hidden;

                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }
        & .actions {
            display: flex;
            flex-wrap: wrap;

            margin-right: -10px;
            & .btn {
                margin-right: 10px;
            }
        }
    }
    & .chat-list {
        padding: 10px;
        & ul {
            max-height: 465px;
            margin: 0;
            padding: 15px;

            list-style: none;
            & li {
                max-width: 100%;
                @media only screen and (min-width: 1200px) {
                    max-width: 60%;
                }
                & + li {
                    margin-top: 15px;
                }
                & .chat {
                    display: flex;
                    flex-wrap: wrap;
                    & + .chat {
                        margin-top: 10px;
                    }
                    & .thumb {
                        overflow: hidden;

                        width: 30px;
                        height: 30px;

                        border-radius: 50%;
                        & img {
                            width: 100%;
                        }
                    }
                    & .content {
                        width: calc(100% - 30px);
                        padding-left: 15px;
                        & .time {
                            font-size: 11px;
                            line-height: 1;

                            display: block;

                            margin-bottom: 5px;

                            opacity: 0.75;
                        }
                        & .text {
                            line-height: 1.75;

                            display: inline-block;

                            margin-bottom: 2px;
                            padding: 5px 12px;

                            border-radius: 4px;
                            background-color: $grey;
                            &:last-child {
                                margin-top: 0;
                            }
                        }
                    }
                }
                &:nth-child(2n) {
                    margin-left: auto;
                    & .chat {
                        flex-direction: row-reverse;
                        & .content {
                            padding-right: 15px;
                            padding-left: 0;

                            text-align: right;
                        }
                    }
                }
            }
        }
    }
    & .chat-form {
        padding: 15px 25px;

        border-top: 1px solid $border-color;
        & form {
            position: relative;
            & .form-control {
                height: 50px;
                padding-right: 60px;
                padding-left: 0;

                border: none;
            }
            & .btn {
                position: absolute;
                right: 0;
                bottom: 0;
            }
        }
    }
}