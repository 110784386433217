/*--
    - Component - Report
--------------------------------------*/

/* Top Report Card */
.top-report-card {
    & .card-head {
        & .title {
        }
        & span {
        }
    }
    & .card-body {
        & .title {
            @media #{$laptop-device} {
                font-size: 24px;
            }
        }
    }
}