/*--
    - List
--------------------------------------*/
/*List Group*/
.list-group {
    list-style: none;
    & .list-group-item {
        margin-top: 0;
        padding: 10px 20px;

        border-color: $border-color;
        &.active {
            border-color: $primary;
            background-color: $primary;
        }
        &.disabled {
            color: $gray-600;
        }
        & .list-group {
            margin-top: 0.75rem;
        }
        @each $name, $color in $theme-colors {
            &-#{$name} {
                background-color: $color;
                @if $name == light or $name == warning or $name == info {
                    color: $body-color;
                } @else {
                    color: $white;
                }
            }
        }
    }
    @each $name, $color in $theme-colors {
        &-#{$name} {
            & .list-group-item {
                background-color: $color;
                @if $name == light or $name == warning or $name == info {
                    color: $body-color;
                } @else {
                    color: $white;
                }
            }
        }
    }
}
