/*--
    - Data Table
--------------------------------------*/

/*Data Table Wrap*/
.data-table-wrap {
    margin-bottom: -20px;
    & .table-responsive {
        padding-bottom: 20px;
    }
}

/* Data Table Select (Checkbox Style) */
.dtr-select {
    display: none;

    & + span {
        position: relative;

        display: block;

        width: 18px;
        height: 18px;

        transition: $transition-base;

        border: 2px solid #C9C9C9;
        border-radius: 3px;
        &::before {
            position: absolute;
            top: 3px;
            left: 1px;

            width: 12px;
            height: 6px;

            content: "";
            transition: $transition-base;
            transform: rotate(-45deg);

            opacity: 0;
            border-color: $white;
            border-bottom-width: 2px;
            border-bottom-style: solid;
            border-left-width: 2px;
            border-left-style: solid;
        }
    }
    &:checked {
        & + span {
            border-color: $primary;
            background-color: $primary;
            &::before {
                opacity: 1;
            }
        }
    }
    &:disabled {
        & + span {
            opacity: 0.35;
        }
    }
}

/* Data Table */
table.data-table {
    flex: 1 0 100%;

    width: 100% !important;
    min-width: 100%;
    margin-top: 0;
    margin-bottom: 10px;

    border-collapse: collapse;

    border-bottom: none !important;
    &:first-child {
        margin-top: 0;
    }
    &:last-child {
        & tbody {
            &:last-child {
                & tr {
                    &:last-child {
                        & td {
                            border-bottom: none;
                        }
                    }
                }
            }
        }
    }
    & thead {
        & tr {
            & th {
                &.sorting {
                    background-position: center right 5px;
                }
            }
        }
    }
    & thead,
    & tbody,
    & tfoot {
        & tr {
            & th,
            & td {
                box-sizing: border-box;
                padding: 12px 20px;

                border-width: 0 1px 1px;
                border-color: $border-color !important;
            }
        }
    }
    & thead,
    & tfoot {
        & tr {
            & th {
                font-weight: 600;

                color: $heading-color;
                background-color: #F9F9F9;
            }
        }
    }
    &.dtr-inline {
        &.collapsed {
            & thead,
            & tbody {
                & tr[role="row"] {
                    & th,
                    & td {
                        &:first-child {
                            padding-left: 30px;
                        }
                    }
                }
            }
            & tbody {
                & tr[role="row"] {
                    & td {
                        &.dtr-control {
                            &::before {
                                font-size: 16px;
                                line-height: 18px;

                                left: 0;

                                width: 18px;
                                height: 18px;
                                margin-top: 0;

                                transform: translateY(-50%);

                                border: none;
                                border-radius: 0;
                                box-shadow: none;
                            }
                        }
                    }
                }
                & tr {
                    &.child {
                        & td.child {
                            padding: 0 20px;
                            & .dtr-details {
                                width: 100%;
                                & li {
                                    display: flex;

                                    margin: 0;
                                    padding: 10px 0;
                                    &:last-child {
                                        border-bottom: none;
                                    }
                                    & .dtr-title {
                                        font-weight: 600;

                                        align-self: center;
                                    }
                                    & .dtr-data {
                                        padding-left: 20px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &.table-borderless {
                & tbody {
                    & tr[role="row"] {
                        &:first-child {
                            & td {
                                &.dtr-control {
                                    &::before {
                                        margin-top: 6px;
                                    }
                                }
                            }
                        }
                        &:last-child {
                            & td {
                                &.dtr-control {
                                    &::before {
                                        margin-top: -6px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.datatable-selectable {
        & thead,
        & tbody,
        & tfoot {
            & tr {
                & th,
                & td {
                    & label {
                        display: block;

                        width: 18px;
                    }
                    &:nth-child(1) {
                        background-image: none !important;
                        & label {
                            margin: auto;
                        }
                    }
                }
            }
        }
    }
}

/*Data Table Wrapper*/
.dataTables_wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;


    & .datatable-topbar {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;

        width: 100%;
        padding: 20px 20px 10px;
        & > * {
            margin-bottom: 10px !important;
        }
        & .datatable-title {
            font-family: $headings-font-family;
            font-size: 18px;
            font-weight: 600;
            line-height: 1.5;

            margin-bottom: 0;

            color: $heading-color;
        }
    }
    & .datatable-footer {
        display: flex;

        width: 100%;
        padding: 10px 20px 20px;
        & > * {
            margin-top: 10px !important;
        }

        /* Informatrion */
        & .dataTables_info {
            align-self: center;

            padding-top: 0;
        }
        /* Pagination */
        & .dataTables_paginate {
            margin-top: 0;
        }
    }

    /* Export Buttons */
    & .dt-buttons {
        display: flex;
        flex-wrap: wrap;

        margin-bottom: 6px;
        & .dt-button {
            margin-right: 6px;
            margin-bottom: 4px;

            background-image: none;

@extend .btn;

@extend .btn-secondary;

@extend .btn-sm;

            &:focus,
            &:hover {
                color: $white;
                border-color: $primary;
                background-color: $primary;
                background-image: none;
            }
        }
    }

    /* Length & Filter */
    & .dataTables_length,
    .dataTables_filter {
        display: flex;

        margin-bottom: 10px;
        & label {
            display: flex;
            align-items: center;

            margin: 0;
            & input {
                margin-left: 6px;

@extend .form-control;

@extend .form-control-sm;
            }
            & select {
                margin: 0 6px;

@extend .form-select;

@extend .form-select-sm;
            }
        }
        // Responsive
        @media #{$small-mobile} {
            flex: 1 0 100%;
        }
    }

    /* Info */
    & .dataTables_info {
        flex-grow: 1;

        text-align: left;
    }

    /* Pagination */
    & .dataTables_paginate {
        display: flex;
        flex-wrap: wrap;

        margin-top: 10px;
        margin-bottom: -6px;
        padding-top: 0;
        & span {
            & .paginate_button {
                margin-right: 3px;
                margin-left: 3px;
                &:first-child {
                    margin-left: 0;
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }
        & .paginate_button {
            margin-right: 6px;
            margin-bottom: 6px;
            margin-left: 6px;
            padding: 8px !important;

            color: $dark;
            background-color: $gray-200;
            background-image: none !important;

@extend .btn;

@extend .btn-sm;

@extend .btn-icon;

            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }

            & i {
                font-size: 18px;
            }

            &:hover {
                @extend .btn-secondary;
            }
            &.disabled {
                opacity: 1;
                color: $dark;
                background-color: $gray-200;
            }
            &.current {
                color: $white !important;

@extend .btn-primary;

                &:hover {
                    color: $white !important;
                }
            }
        }
    }
}
