.mail-container-wrapper {
    display: flex;
    flex-wrap: wrap;

    height: calc(100vh - 140px);
    @media #{$large-mobile} {
        height: auto;
    }
}

.mail-listing {
    width: 400px;
    height: 100%;
    padding-top: 50px;

    background-color: $white;
    @media #{$desktop-device,
    $tablet-device} {
        width: 300px;
    }
    @media #{$large-mobile} {
        width: 100%;
    }
    & .mail-listing-title {
        margin-bottom: 0;
    }
    & .mail-listing-actions {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        padding: 0 30px;
        & .mail-listing-action-item {
            position: relative;
            &:first-child {
                margin-right: auto;
            }
            & .form-select {
                height: auto;
                padding: 0;
                padding-right: 20px;

                border: none;
                background-position: right center;
                &.exta-pd {
                    padding-left: 10px;
                    margin-left: -10px;
                }
            }
            & .btn {
                width: auto;
                height: auto;
                & svg {
                    width: 18px;
                    height: 18px;
                }
            }
        }
    }
    & .mail-list {
        height: calc(100vh - 300px);
        margin: 20px 0 0;
        padding: 0;

        list-style: none;
        @media #{$large-mobile} {
            height: 300px;
        }
        & li {
            display: flex;
            flex-wrap: wrap;

            margin: 0;
            padding: 30px;

            border-top: 2px solid $grey;
            & .thumb {
                display: block;
                overflow: hidden;

                width: 60px;
                height: 60px;

                border-radius: 50%;
                & img {
                    width: 100%;
                    height: 100%;

                    object-fit: cover;
                }
            }
            & .info {
                width: calc(100% - 60px);
                padding-left: 30px;
                @media #{$desktop-device,
                $tablet-device} {
                    width: 100%;
                    padding-top: 10px;
                    padding-left: 0;
                }
                & .head {
                    display: flex;
                    flex-wrap: wrap;

                    margin-bottom: 8px;
                    & .name {
                        width: calc(100% - 55px);
                        margin-bottom: 0;
                    }
                    & .labels {
                        display: flex;
                        align-items: flex-start;
                        align-self: flex-start;
                        justify-content: space-between;

                        width: 55px;
                        & .label {
                            line-height: 1;

                            height: 27px;
                            padding: 5px;

                            color: #AAAAAA;
                            border: none;
                            background-color: transparent;
                            &:last-child {
                                margin-left: auto;
                            }
                            & i {
                                line-height: 17px;
                            }
                            & svg {
                                width: 16px;
                                height: 16px;
                            }
                            &.attached {
                                color: #F56B6B;
                            }
                            &.pined {
                                color: $primary;
                            }
                        }
                    }
                }
                & .subject {
                    font-size: 14px;
                    font-weight: 400;
                }
                & .summery {
                    margin-bottom: 8px;
                    & p {
                    }
                }
                & .date {
                }
            }
            &.active {
                background-color: $grey;
            }
        }
    }
}

.single-mail {
    overflow: auto;

    width: calc(100% - 400px);
    height: 100%;
    @media #{$desktop-device,
    $tablet-device} {
        width: calc(100% - 300px);
    }
    @media #{$large-mobile} {
        width: 100%;
    }
    & .single-mail-inner {
        padding: 100px;
        @media #{$desktop-device,
        $tablet-device} {
            padding: 50px 40px;
        }
        @media #{$large-mobile} {
            padding: 30px;
        }
        & .mail-head {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            margin-bottom: 20px;
            & .subject {
                margin-bottom: 10px;
            }
            & .meta {
                display: flex;
                align-items: center;
                flex-wrap: wrap;

                margin-bottom: 0;
                padding: 0;

                list-style: none;
                & li {
                    margin-top: 0;
                    margin-right: 20px;
                    margin-bottom: 10px;

                    color: #666666;
                    &:first-child {
                        margin-right: 30px;
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                    & i {
                    }
                    & a {
                    }
                }
            }
        }
        & .mail-user {
            display: flex;
            flex-wrap: wrap;

            margin-bottom: 30px;
            & .thumb {
                & img {
                }
            }
            & .info {
                align-self: center;

                padding-left: 30px;
                & .name {
                }
                & .email {
                }
            }
        }
        & .mail-body {
            & h5 {
                margin-bottom: 15px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            & p {
                margin-bottom: 20px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            & .attachments {
                display: inline-flex;
                flex-wrap: wrap;

                padding: 10px;

                border-radius: 10px;
                background-color: $white;
                & .item {
                    padding: 5px;
                    & img {
                        border-radius: 10px;
                    }
                }
            }
        }
        & .mail-buttons {
            display: flex;
            flex-wrap: wrap;

            margin-top: 20px;
            & .btn {
                height: auto;
                margin-right: 40px;
                padding: 0;

                color: #919BB0;
                border: none;
                &:hover {
                    color: $primary;
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }
        & hr {
            margin: 30px 0;

            background-color: #919BB0;
            &:first-child {
                margin-top: 0;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}