.task-list {
    overflow: hidden;

    border-radius: 10px;
    background-color: $white;
}
.task-item {
    padding: 30px;
    & + .task-item {
        border-top: 2px solid $border-color;
    }
    & .topbar {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;

        margin-bottom: 9px;
        & .date {
            line-height: 1;

            display: flex;
            align-items: center;

            color: $primary;
            & i {
                margin-top: 1px;
                margin-right: 10px;
            }
        }
        & .settings {
            & .btn {
                height: auto;

                color: #979797;
            }
        }
    }
    & .title {
        margin-bottom: 18px;
    }
    & .text {
        margin-bottom: 10px;
    }
    & .bottombar {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;

        margin-bottom: 15px;
        & .labels {
            display: flex;
            & .label {
                display: block;

                width: 35px;
                height: 10px;

                border-radius: 10px;
            }
        }
        & .attachments {
            line-height: 1;
            & svg {
                width: 16px;
                height: 16px;

                color: #9A9A9A;
            }
        }
    }
    & .links {
        & .link {
            margin-right: 30px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
}