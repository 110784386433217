/*--
    - Quill
--------------------------------------*/

.quill {
    & .ql-toolbar {
        border-color: $border-color;
        & .ql-formats {
            & .ql-picker {
                & .ql-picker-label {
                }
                & .ql-picker-options {
                    border-color: $border-color;
                }
                &.ql-expanded {
                    & .ql-picker-label {
                        border-color: $border-color;
                    }
                }
            }
        }
    }
    & .quill-editor {
        border-color: $border-color;
    }
}