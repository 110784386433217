.dropify-wrapper {
    border-color: $border-color;
    & .dropify-message {
        & .file-icon {
            & p {
                font-size: 14px;
            }
        }
    }
    & .dropify-preview {
        & .dropify-render {
            width: 100%;
            height: 100%;
            & img {
                width: 100%;
                height: 100%;

                object-fit: cover;
            }
        }
    }
}

.dropzone {
    display: flex;
    flex-wrap: wrap;

    height: 100%;
    padding: 10px;

    border-color: $border-color;
    & .dz-message {
        display: flex;
        align-items: center;
        flex: 1 0 calc(100% - 20px);
        justify-content: center;
        order: 12;

        width: calc(100% - 20px);
        min-height: 100px;
        margin: 10px;
        padding: 15px;

        border-radius: 5px;
        background-color: #F7F8F9;
    }
    &.dz-started {
        & .dz-preview {
            min-height: auto;
            margin: 10px;

            border-radius: 5px;
            & .dz-image {
                width: 100%;
                height: auto;

                border-radius: 5px;
                & img {
                    width: 100%;
                }
            }
            & .dz-success-mark,
            & .dz-error-mark {
                width: 50px;
                height: 50px;
                margin: 0;

                transform: translateX(-50%) translateY(-50%);
                & svg {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        &.multiple {
            & .dz-message {
                display: flex;
            }
        }
        &.dropzone-5-column {
            & .dz-preview {
                flex: 1 0 calc(20% - 20px);

                max-width: calc(20% - 20px);
            }
        }
        &.dropzone-4-column {
            & .dz-preview {
                flex: 1 0 calc(25% - 20px);

                max-width: calc(25% - 20px);
            }
        }
        &.dropzone-3-column {
            & .dz-preview {
                flex: 1 0 calc(33.3333% - 20px);

                max-width: calc(33.3333% - 20px);
            }
        }
        &.dropzone-2-column {
            & .dz-preview {
                flex: 1 0 calc(50% - 20px);

                max-width: calc(50% - 20px);
            }
        }
    }
}
